import axios from "axios"
import router from '@/router'


// const baseURL = 'http://shgw.com/index.php'//本地地址
const baseURL = 'https://www.szart.cn/api/public/index.php'//线上地址

// 1.创建一个新的请求实例 
const instance = function(url) {
    // console.log(url)
    return axios({
        method: url.method || 'get',
        url: baseURL + url.url,
        headers: {
            
        },
        data: url.data
    }).then(res => {
        return res.data
    }).catch(err => {

    })
    
}
export default instance