import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const index = () => import('@/pages/index')   // 首页
const survey = () => import('@/pages/survey')  //学院概况

// 教学机构
const mechanismIndex = () => import("@/pages/mechanism/index")  //首页
const mechanism = () => import('@/pages/mechanism/mechanism')  //教学机构-成人部
const shaoer = () => import('@/pages/mechanism/shaoer')  //教学机构-少儿
const mechanismDetail = () => import('@/pages/mechanism/mechanismDetail')  //教学机构-成人部详情
const student = () => import('@/pages/mechanism/student')  //教学机构-学员作品
const studentDetail = () => import('@/pages/mechanism/studentDetail')  //教学机构-学员作品详情

// 艺术学会
const society = () => import('@/pages/society/index')  //首页
const introduce = () => import('@/pages/society/introduce')  //学会介绍
const elegant = () => import('@/pages/society/elegant')  //优秀学员
const elegantDetail = () => import('@/pages/society/elegantDetail')  //学员详情
const societyNew = () => import('@/pages/society/societyNew')  //学会新闻



// 考级
const finArt = () => import('@/pages/finArt/index')  //首页
const standard = () => import('@/pages/finArt/standard')  //考级标准
const finArtDetail = () => import('@/pages/finArt/finArtDetail')  //美术考级详情
const brief = () => import('@/pages/finArt/brief')  //考级简介
const guide = () => import('@/pages/finArt/guide')  //考级指南


// 师资队伍
const teachers = () => import("@/pages/teachers/index")   


// 新闻
const news = () => import('@/pages/news/index')   // 新闻首页
const newsCenter = () => import('@/pages/news/newsCenter')   // 新闻列表
const newDetail = () => import('@/pages/news/newDetail')  // 新闻详情
const newActive = () => import('@/pages/news/newActive')  // 新闻动态
const exhibition = () => import('@/pages/news/exhibition')  // 展览中心

const routes = [
  {
    path: '',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: index
  },
  // 学院概括
  {
    path: '/survey',
    name: 'survey',
    component: survey
  },
  // 师资队伍
  {
    path: '/teachers',
    name: 'teachers',
    component: teachers
  },
  // 教学机构
  {
    path: '/mechanismIndex',
    name: 'mechanismIndex',
    component: mechanismIndex,
    children: [
      {
        path: '',
        redirect: '/mechanismIndex/mechanism'
      },
      {
        path: 'mechanism',
        component: mechanism
      },
      {
        path: 'student',
        component: student
      },
      {
          path: 'shaoer',
          component: shaoer
      },
      {
        path: '/studentDetail',
        name: 'studentDetail',
        component: studentDetail
      },
    ]
  },
  {
    path: '/mechanismDetail',
    name: 'mechanismDetail',
    component: mechanismDetail
  },
  
  


  // 艺术学会
  {
    path: '/society',
    name: 'society',
    component: society,
    children: [
      {
        path: '',
        redirect: '/society/introduce'
      },
      {
        path: 'introduce',
        component: introduce
      },
      {
        path: 'elegant',
        component: elegant
      },
      {
        path: 'elegantDetail',
        component: elegantDetail
      },
      {
        path: 'societyNew',
        component: societyNew
      }
    ]
  },

  // 考级
  {
    path: '/finArt',
    name: 'finArt',
    component: finArt,
    children: [
      {
        path: '',
        redirect: '/finArt/brief'
      },
       {
         path: 'standard',
         component: standard
       },
       {
         path: 'finArtDetail',
         component:finArtDetail
       },
       {
        path: 'brief',
        component: brief
       },
       {
        path: 'guide',
        component: guide
       }
    ]
  },
  // 新闻
  {
    path: '/news',
    name: 'news',
    component: news,
    children: [
      {
        path: '',
        redirect: '/news/newsCenter',
        
      },
      {
        path: 'newsCenter',
        component: newsCenter
      },
      {
        path: 'newDetail',
        component: newDetail
      },
      {
        path: 'newActive',
        component: newActive
      },
      {
        path: 'exhibition',
        component: exhibition
      }
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

const VueRouterPush = VueRouter.prototype.push   // 获取push的路径
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)   //如果push的值和原本的值相等， 则return
}

export default router
