import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AMap from 'vue-amap';
Vue.use(AMap);


import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

import $requst from './utils/Http.js'
Vue.prototype.$request = $requst

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);


AMap.initAMapApiLoader({
    key: 'dcb30501937d20bc502723b645337b55',//刚刚开发者申请哪里的key
    plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType']
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
